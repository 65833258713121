import apiClient from "./api-client";
import {BASE_URL, EXP_URL, PBI_RESOURCE_URL, MOBILE_TOKEN, APPLICATION, contentTypes} from "./apiConfig";


// Base function for GET requests
const get = async (route, { body = {}, user = {} }) => {
  
  let headers = {
    Accept: contentTypes["json"],
    Application: APPLICATION,
    "Content-Type": contentTypes["json"],
    
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {    
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "get",
    mode: 'cors',
    url: `${EXP_URL}/${route}`,
    headers,
    params: body,
  });
};
// Base function for POST requests
const post = async (route, { body, user = {}, contentType='application/json' }) => {
  let headers = {
    Accept: contentType,
    Application: APPLICATION,
    "Content-Type": contentType,  
    "withCredentials": true  ,
    'Access-Control-Allow-Origin': '*'
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "post",
    url: `${EXP_URL}/${route}`,
    headers,
    data: body,
  });
};
// Base function for PUT requests
const put = async (route, { body, user = {}, contentType='application/json' }) => {
  let headers = {
    Accept: contentType,
    Application: APPLICATION,
    "Content-Type": contentType,  
    "withCredentials": true  ,
    'Access-Control-Allow-Origin': '*'
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "put",
    url: `${EXP_URL}/${route}`,
    headers,
    data: body,
  });
};
// Base function for DELETE requests
const deleteApiCall = async (route, { body, user = {}, contentType='application/json' }) => {
  let headers = {
    Accept: contentType,
    Application: APPLICATION,
    "Content-Type": contentType,  
    "withCredentials": true  ,
    'Access-Control-Allow-Origin': '*'
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "delete",
    url: `${EXP_URL}/${route}`,
    headers,
    data: body,
  });
};
// Base function for GET requests
const nonExpGet = async (route, { body = {}, user = {} }) => {
  
  let headers = {
    Accept: contentTypes["json"],
    Application: APPLICATION,
    "Content-Type": contentTypes["json"],
    
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {    
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "get",
    mode: 'cors',
    url: `${BASE_URL}/${route}`,
    headers,
    data: body,
  });
};

// Base function for POST requests
const nonExpPost = async (route, { body, user = {}, contentType='application/json' }) => {
  let headers = {
    Accept: contentType,
    Application: APPLICATION,
    "Content-Type": contentType,  
    "withCredentials": true  ,
    'Access-Control-Allow-Origin': '*'
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "post",
    url: `${BASE_URL}/${route}`,
    headers,
    data: body,
  });
};
// Base function for POST requests
const nonExpPut = async (route, { body, user = {}, contentType='application/json' }) => {
  let headers = {
    Accept: contentType,
    Application: APPLICATION,
    "Content-Type": contentType,  
    "withCredentials": true  ,
    'Access-Control-Allow-Origin': '*'
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  return apiClient({
    method: "put",
    url: `${BASE_URL}/${route}`,
    headers,
    data: body,
  });
};



const pbiGet = async (route, { body = {}, user = {} }) => {
  
  let headers = {   
    Application: APPLICATION,
    "Content-Type": contentTypes["json"],
    'Access-Control-Allow-Origin': '*'
    
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {    
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  
  return apiClient({
    method: "get",
    
    url: `${PBI_RESOURCE_URL}/${route}`,
    headers,
    data: body,
  });
};
const pbiPost = async (route, { body = {}, user = {} }) => {
  
  let headers = {   
    Application: APPLICATION,
    "Content-Type": contentTypes["json"],
    'Access-Control-Allow-Origin': '*'
    
  };
  let sessionId = localStorage.getItem("sessionId");
  headers["SessionId"] = `${sessionId}`;
  if (user.applicationToken) {
    headers["Application-token"] = `${user.applicationToken}`;
    headers["Mobile-token"] = `${user.applicationToken}`;
  }
  if (user.token) {    
    headers["Authorization"] = `Bearer ${user.token}`;
  }
  
  return apiClient({
    method: "post",
    
    url: `${PBI_RESOURCE_URL}/${route}`,
    headers,
    data: body,
  });
};

// Routes
const routes = {
  applicationToken: "application-token",
  getWebsiteNameLogo: "exp/client/nameAndLogo",
  login: "exp/login",
  createClient: "admin/client",
  updateClient: "admin/client",
  createPbiCofig: "client",
  searchClients: "admin/client/search",  
  getEmbedToken: "pbiResources/embedToken",
};

export { routes, get, post, put, deleteApiCall, pbiGet, pbiPost, nonExpGet,  nonExpPost, nonExpPut};

export { applicationToken, getWebsiteNameLogo, login } from "./auth";
export { createClient, getClient, updateClient, searchClients, createPbiConfig, searchClientUsers, createClientUser, pbiResourcesRefresh, searchClientWorkspaces, searchClientSecurityGroups, deleteClientSecurityGroup, createClientSecurityGroup, updateClientSecurityGroup, getClientWorkspace, createClientUserSecurityGroupLink, createClientWorkspaceSecurityGroupLink, GetReportPages, GetReportPage,  GetDashboardTiles, GetDashboardTile, searchTrace, updateClientUser, uploadClientLogo, searchClientSubscriptions, searchPbiReports, searchPbiDashboard, searchPbiDataset, createClientSubscription, subscriptionTriggerNow, getClientSubscriptions, getClientUser, searchClientPbiResources, searchSecurityGroupUsers} from "./manageClient";
export { getEmbedToken, searchPbiResources, getPbiResources, getResourceEmbedToken} from "./pbiResource";
