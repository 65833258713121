import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./assets/theme/css/bootstrap.min.css";
import "./assets/theme/css/all.min.css";
// import "./assets/theme/css/classy-nav.css";
import "./assets/theme/css/owl.carousel.min.css";
import "./assets/theme/css/nice-select.css";
import "./assets/theme/css/simplebar.css";
import "./assets/theme/css/audioplayer.css";
import "./assets/theme/css/style.css";
import "./assets/theme/css/style-2.css";
import "./assets/theme/css/responsive.css";

import "./assets/theme/css/react-style.css";
import "./assets/theme/css/theme-style.css";




import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./store/store";

import { getMessaging, getToken, onMessage} from 'firebase/messaging';

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";
import { msalConfig } from "./utils/authConfig";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
/**
 * admin routes
 */
import Counter from "./views/counter";
import Dashboard from "./views/admin/dashboard/Dashboard";
import DashboardNew from "./views/admin/dashboard-new/Dashboard";
import ClientCreate from "./views/admin/clients/ClientCreate";
import ClientUpdate from "./views/admin/clients/ClientUpdate";
import ClientList from "./views/admin/clients/ClientList";
import ClientUserList from "./views/admin/clients/ClientUserList";
import ClientUserCreate from "./views/admin/clients/ClientUserCreate";
import ClientSecurityGroups from "./views/admin/clients/ClientSecurityGroups";
import ClientSecurityGroupWorkspaces from "./views/admin/clients/ClientSecurityGroupWorkspaces";
import ClientSecurityGroupCreate from "./views/admin/clients/ClientSecurityGroupCreate";
import ClientSecurityGroupUpdate from "./views/admin/clients/ClientSecurityGroupUpdate";
import ClientWorkspaces from "./views/admin/clients/ClientWorkspaces";
import ClientWorkspaceView from "./views/admin/clients/ClientWorkspaceView";
import ClientEmbededView from "./views/admin/clients/ClientEmbededView";
import ClientGroupMap from "./views/admin/clients/ClientGroupMap";
import ClientSecurityGroupUserMapList from "./views/admin/clients/ClientSecurityGroupUserMapList";
import ClientSecurityGroupWorkspaceMapList from "./views/admin/clients/ClientSecurityGroupWorkspaceMapList";
import ClientWorkspaceGroupMap from "./views/admin/clients/ClientWorkspaceGroupMap";
import ClientPbiConfig from "./views/admin/clients/ClientPbiConfig";
import ClientReportPages from "./views/admin/clients/ClientReportPages";
import ClientDashboardTiles from "./views/admin/clients/ClientDashboardTiles";
import ActivityLog from "./views/admin/clients/ActivityLog";
import ClientWorkspaceUpdate from "./views/admin/clients/ClientWorkspaceUpdate";
import ClientResourceUpdate from "./views/admin/clients/ClientResourceUpdate";
import ClientUserUpdate from "./views/admin/clients/ClientUserUpdate";
import ClientLogo from "./views/admin/clients/ClientLogo";
import ClientCreatedNotifications from "./views/admin/clients/ClientCreatedNotifications";
import ClientNotificationsCreate from "./views/admin/clients/ClientNotificationsCreate";
import ClientSubscriptions from "./views/admin/clients/ClientSubscriptions";
import ClientSubscriptionCreate from "./views/admin/clients/ClientSubscriptionCreate";
import ClientSubscriptionUpdate from "./views/admin/clients/ClientSubscriptionUpdate";
import ClientNotificationActivities from "./views/admin/clients/ClientNotificationActivities";
import ClientUserSecurityGroups from "./views/admin/clients/ClientUserSecurityGroups";
import ClientSecurityGroupUsers from "./views/admin/clients/ClientSecurityGroupUsers";
import ClientActiveDevices from "./views/admin/clients/ActiveDevices";

import Reports from "./views/admin/power-bi/Reports";
import MobileReports from "./views/admin/power-bi/MobileReports";
import Qna from "./views/admin/power-bi/Qna";
import EmbedPage from "./views/admin/power-bi/EmbedPage";
import ErrorPage from "./views/admin/ErrorPage";



/**
 * Clinet admin routes
 */
import ClientAdminErrorPage from "./views/clientAdmin/ErrorPage";
import ClientAdminDashboard from "./views/clientAdmin/dashboard/Dashboard";
import ClientAdminClientUserList from "./views/clientAdmin/clients/ClientUserList";
import ClientAdminClientUserCreate from "./views/clientAdmin/clients/ClientUserCreate";
import ClientAdminClientWorkspaces from "./views/clientAdmin/clients/ClientWorkspaces";
import ClientAdminClientWorkspaceView from "./views/clientAdmin/clients/ClientWorkspaceView";
import ClientAdminClientEmbededView from "./views/clientAdmin/clients/ClientEmbededView";
import ClientAdminClientSecurityGroups from "./views/clientAdmin/clients/ClientSecurityGroups";
import ClientAdminClientSecurityGroupCreate from "./views/clientAdmin/clients/ClientSecurityGroupCreate";
import ClientAdminClientSecurityGroupUpdate from "./views/clientAdmin/clients/ClientSecurityGroupUpdate";
import ClientAdminClientGroupMap from "./views/clientAdmin/clients/ClientGroupMap";
import ClientAdminClientWorkspaceGroupMap from "./views/clientAdmin/clients/ClientWorkspaceGroupMap";
import ClientAdminClientWorkspaceGroupMapUpdate from "./views/clientAdmin/clients/ClientWorkspaceGroupMapUpdate";
import ClientAdminClientCreatedNotifications from "./views/clientAdmin/clients/ClientCreatedNotifications";
import ClientAdminClientNotificationsCreate from "./views/clientAdmin/clients/ClientNotificationsCreate";
import ClientAdminClientReportPages from "./views/clientAdmin/clients/ClientReportPages";
import ClientAdminClientDashboardTiles from "./views/clientAdmin/clients/ClientDashboardTiles";
import ClientAdminMyNotifications from "./views/clientAdmin/clients/MyNotifications";
import ClientAdminClientWorkspaceUpdate from "./views/clientAdmin/clients/ClientWorkspaceUpdate";
import ClientAdminClientResourceUpdate from "./views/clientAdmin/clients/ClientResourceUpdate";
import ClientAdminClientUserUpdate from "./views/clientAdmin/clients/ClientUserUpdate";
import ClientAdminClientSubscriptions from "./views/clientAdmin/clients/ClientSubscriptions";
import ClientAdminClientSubscriptionCreate from "./views/clientAdmin/clients/ClientSubscriptionCreate";
import ClientAdminClientSubscriptionUpdate from "./views/clientAdmin/clients/ClientSubscriptionUpdate";
import ClientAdminClientTileEmbededView from "./views/clientAdmin/clients/ClientTileEmbededView";
import ClientAdminClientPaginatedEmbededView from "./views/clientAdmin/clients/ClientPaginatedEmbededView";
import ClientAdminActivities from "./views/clientAdmin/clients/UserActivities";
import ClientAdminNotificationActivities from "./views/clientAdmin/clients/ClientNotificationActivities";
import ClientAdminClientUserSecurityGroups from "./views/clientAdmin/clients/ClientUserSecurityGroups";
import ClientAdminActiveDevices from "./views/clientAdmin/clients/ActiveDevices";
import ClientAdminClientSecurityGroupUsers from "./views/clientAdmin/clients/ClientSecurityGroupUsers";
import ClientAdminSecurityGroupsWorkspaces from "./views/clientAdmin/clients/SecurityGroupsWorkspaces";

/**
 * user routes
 */
import UserErrorPage from "./views/user/ErrorPage";
import UserDashboard from "./views/user/dashboard/Dashboard";
import UserClientWorkspaces from "./views/user/clients/ClientWorkspaces";
import UserClientWorkspaceView from "./views/user/clients/ClientWorkspaceView";
import UserClientEmbededView from "./views/user/clients/ClientEmbededView";
import UserMyNotifications from "./views/user/clients/MyNotifications";
import UserClientReportPages from "./views/user/clients/ClientReportPages";
import UserClientDashboardTiles from "./views/user/clients/ClientDashboardTiles";
import UserClientTileEmbededView from "./views/user/clients/ClientTileEmbededView";
import UserClientPaginatedEmbededView from "./views/user/clients/ClientPaginatedEmbededView";
import UserActivities from "./views/user/clients/UserActivities";
import ActiveDevices from "./views/user/clients/ActiveDevices";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});
const router = createBrowserRouter([
  {
    path: "/",
    element: <App instance={msalInstance} />,
  },
  {
    path: "/client-create",
    element: <ClientCreate instance={msalInstance} />,
  },
  {
    path: "/client-update/:clientId",
    element: <ClientUpdate instance={msalInstance} />,
  },
  {
    path: "/client-pbi-config/:clientId",
    element: <ClientPbiConfig instance={msalInstance} />,
  },
  {
    path: "/client-list",
    element: <ClientList instance={msalInstance} />,
  },
  {
    path: "/client-user-list/:clientId",
    element: <ClientUserList instance={msalInstance} />,
  },
  {
    path: "/client-user-create/:clientId",
    element: <ClientUserCreate instance={msalInstance} />,
  },
  {
    path: "/client-security-groups/:clientId",
    element: <ClientSecurityGroups instance={msalInstance} />,
  },
  {
    path: "/client-security-group-workspaces/:securityGroupId/:clientId",
    element: <ClientSecurityGroupWorkspaces instance={msalInstance} />,
  },
  {
    path: "/client-security-group-user-map-list/:clientId",
    element: <ClientSecurityGroupUserMapList instance={msalInstance} />,
  },
  {
    path: "/client-security-group-workspace-map-list/:clientId",
    element: <ClientSecurityGroupWorkspaceMapList instance={msalInstance} />,
  },

  {
    path: "/client-security-group-update/:securityGroupId/:clientId",
    element: <ClientSecurityGroupUpdate instance={msalInstance} />,
  },
  {
    path: "/client-security-group-create/:clientId",
    element: <ClientSecurityGroupCreate instance={msalInstance} />,
  },
  {
    path: "/client-workspaces/:clientId",
    element: <ClientWorkspaces instance={msalInstance} />,
  },
  {
    path: "/client-workspace-view/:clientId/:workspaceId",
    element: <ClientWorkspaceView instance={msalInstance} />,
  },
  {
    path: "/client-embeded-view/:clientId/:resourceId",
    element: <ClientEmbededView instance={msalInstance} />,
  },
  {
    path: "/client-user-security-groups/:userId/:clientId",
    element: <ClientUserSecurityGroups instance={msalInstance} />,
  },
  {
    path: "/client-security-group-users/:securityGroupId/:clientId",
    element: <ClientSecurityGroupUsers instance={msalInstance} />,
  },
  {
    path: "/client-group-map/:clientId",
    element: <ClientGroupMap instance={msalInstance} />,
  },
  {
    path: "/client-workspace-group-map/:clientId",
    element: <ClientWorkspaceGroupMap instance={msalInstance} />,
  },
  {
    path: "/reports",
    element: <Reports instance={msalInstance}  />,
  },
  {
    path: "/mobile-reports",
    element: <MobileReports instance={msalInstance} />,
  },
  {
    path: "/qna",
    element: <Qna instance={msalInstance} />,
  },
  {
    path: "/embed-page",
    element: <EmbedPage />,
  },
  {
    path: "/error-page",
    element: <ErrorPage instance={msalInstance} />,
  },
  // {
  //   path: "/dashboard",
  //   element: <Dashboard instance={msalInstance} />,
  // },
  {
    path: "/dashboard",
    element: <DashboardNew instance={msalInstance} />,
  },
  {
    path: "/client-report-pages/:reportId",
    element: <ClientReportPages instance={msalInstance} />,
  },
  {
    path: "/client-dashboard-tiles/:dashboardId",
    element: <ClientDashboardTiles instance={msalInstance} />,
  },
  {
    path: "/activity-log",
    element: <ActivityLog instance={msalInstance} />,
  },
  {
    path: "/active-devices",
    element: <ClientActiveDevices instance={msalInstance} />,
  },
  {
    path: "/client-workspace-update/:clientId/:workspaceId",
    element: <ClientWorkspaceUpdate instance={msalInstance} />,
  },
  {
    path: "/client-resource-update/:clientId/:resourceId",
    element: <ClientResourceUpdate instance={msalInstance} />,
  },

  {
    path: "/client-user-update/:clientId/:userId",
    element: <ClientUserUpdate instance={msalInstance} />,
  },
  {
    path: "/client-logo/:clientId",
    element: <ClientLogo instance={msalInstance} />,
  },
  {
    path: "/client-notifications",
    element: <ClientCreatedNotifications instance={msalInstance} />,
  },
  {
    path: "/client-notification-create",
    element: <ClientNotificationsCreate instance={msalInstance} />,
  },
  {
    path: "/client-subscriptions/:clientId",
    element: <ClientSubscriptions instance={msalInstance} />,
  },
  {
    path: "/client-subscription-create/:clientId",
    element: <ClientSubscriptionCreate instance={msalInstance} />,
  },
  {
    path: "/client-subscription-update/:subscriptionId/:clientId",
    element: <ClientSubscriptionUpdate instance={msalInstance} />,
  },
  {
    path: "/client-notification-activities/:notificationId",
    element: <ClientNotificationActivities instance={msalInstance} />,
  },
  /**
   * client admin routes
   */
  {
    path: "client/error-page",
    element: <ClientAdminErrorPage instance={msalInstance} />,
  },
  {
    path: "/client/dashboard",
    element: <ClientAdminDashboard instance={msalInstance} />,
  },
  {
    path: "/client/users-list",
    element: <ClientAdminClientUserList instance={msalInstance} />,
  },
  {
    path: "/client/client-user-create",
    element: <ClientAdminClientUserCreate instance={msalInstance} />,
  },
  {
    path: "/client/workspaces",
    element: <ClientAdminClientWorkspaces instance={msalInstance} />,
  },
  {
    path: "/client/workspace-view/:workspaceId",
    element: <ClientAdminClientWorkspaceView instance={msalInstance} />,
  },
  {
    path: "/client/embeded-view/:resourceId",
    element: <ClientAdminClientEmbededView instance={msalInstance} />,
  },
  {
    path: "/client/security-groups",
    element: <ClientAdminClientSecurityGroups instance={msalInstance} />,
  },
  {
    path: "/client/security-group-create",
    element: <ClientAdminClientSecurityGroupCreate instance={msalInstance} />,
  },
  {
    path: "/client/security-group-update/:id",
    element: <ClientAdminClientSecurityGroupUpdate instance={msalInstance} />,
  },
  {
    path: "/client/client-group-map",
    element: <ClientAdminClientGroupMap instance={msalInstance} />,
  },
  {
    path: "/client/client-Workspace-group-map",
    element: <ClientAdminClientWorkspaceGroupMap instance={msalInstance} />,
  },
  {
    path: "/client/client-Workspace-group-map-update/:id",
    element: <ClientAdminClientWorkspaceGroupMapUpdate instance={msalInstance} />,
  },
  {
    path: "/client/created-notifications",
    element: <ClientAdminClientCreatedNotifications instance={msalInstance} />,
  },
  {
    path: "/client/client-notification-create/",
    element: <ClientAdminClientNotificationsCreate instance={msalInstance} />,
  },
  {
    path: "client/report-pages/:reportId",
    element: <ClientAdminClientReportPages instance={msalInstance} />,
  },
  {
    path: "/client/dashboard-tiles/:dashboardId",
    element: <ClientAdminClientDashboardTiles instance={msalInstance} />,
  },
  {
    path: "/client/my-notifications",
    element: <ClientAdminMyNotifications instance={msalInstance} />,
  },
  {
    path: "/client/workspace-update/:workspaceId",
    element: <ClientAdminClientWorkspaceUpdate instance={msalInstance} />,
  },
  {
    path: "/client/resource-update/:resourceId",
    element: <ClientAdminClientResourceUpdate instance={msalInstance} />,
  },
  {
    path: "client/user-update/:userId",
    element: <ClientAdminClientUserUpdate instance={msalInstance} />,
  },
  {
    path: "client/subscriptions",
    element: <ClientAdminClientSubscriptions instance={msalInstance} />,
  },
  {
    path: "/client/subscription-create/",
    element: <ClientAdminClientSubscriptionCreate instance={msalInstance} />,
  },
  {
    path: "/client/subscription-update/:subscriptionId/",
    element: <ClientAdminClientSubscriptionUpdate instance={msalInstance} />,
  },
  {
    path: "/client/tile-embeded-view/:dashboardId/:resourceId",
    element: <ClientAdminClientTileEmbededView instance={msalInstance} />,
  },
  {
    path: "/client/paginated-embeded-view/:reportId/:resourceId",
    element: <ClientAdminClientPaginatedEmbededView instance={msalInstance} />,
  },
  {
    path: "/client/user-activities/",
    element: <ClientAdminActivities instance={msalInstance} />,
  },
  {
    path: "/client/notification-activities/:notificationId",
    element: <ClientAdminNotificationActivities instance={msalInstance} />,
  },
  {
    path: "/client/user-security-groups/:userId/",
    element: <ClientAdminClientUserSecurityGroups instance={msalInstance} />,
  },
  {
    path: "/client/client-security-group-users/:securityGroupId/",
    element: <ClientAdminClientSecurityGroupUsers instance={msalInstance} />,
  },
  {
    path: "/client/active-devices/",
    element: <ClientAdminActiveDevices instance={msalInstance} />,
  },
  {
    path: "/client/security-groups-workspaces/",
    element: <ClientAdminSecurityGroupsWorkspaces instance={msalInstance} />,
  },

  /**
   * user routes
   */

  {
    path: "user/error-page",
    element: <UserErrorPage instance={msalInstance} />,
  },
  {
    path: "/user/dashboard",
    element: <UserDashboard instance={msalInstance} />,
  },
  {
    path: "/user/workspaces",
    element: <UserClientWorkspaces instance={msalInstance} />,
  },
  {
    path: "/user/workspace-view/:workspaceId",
    element: <UserClientWorkspaceView instance={msalInstance} />,
  },

  {
    path: "/user/embeded-view/:resourceId",
    element: <UserClientEmbededView instance={msalInstance} />,
  },
  {
    path: "/user/my-notifications",
    element: <UserMyNotifications instance={msalInstance} />,
  },
  {
    path: "user/report-pages/:reportId",
    element: <UserClientReportPages instance={msalInstance} />,
  },
  {
    path: "/user/dashboard-tiles/:dashboardId",
    element: <UserClientDashboardTiles instance={msalInstance} />,
  },
  {
    path: "/user/tile-embeded-view/:dashboardId/:resourceId",
    element: <UserClientTileEmbededView instance={msalInstance} />,
  },
  {
    path: "/user/paginated-embeded-view/:reportId/:resourceId",
    element: <UserClientPaginatedEmbededView instance={msalInstance} />,
  },
  {
    path: "/user/user-activities/",
    element: <UserActivities instance={msalInstance} />,
  },
  {
    path: "/user/active-devices/",
    element: <ActiveDevices instance={msalInstance} />,
  },

]);
onMessage((payload) => {
  console.log('Message received:', payload);
  // Handle incoming messages here
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RouterProvider router={router} />
    </PersistGate>
  </Provider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
